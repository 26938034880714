/* eslint import/namespace: ['error', { allowComputed: true }] */

import Vue from 'vue';
import { transliterate as tr, slugify } from 'transliteration';
import md5 from 'md5';
import pricingUtilities from '@cabinetmariel/pricing-utilities';
import pricingMethods from '@cabinetmariel/pricing-methods';
import { computearrayaverage2, featurevalue } from '@cabinetmariel/pricing-methods/features';
import { localization } from '../../lib/mixins';
import {
  mkemptyvalidatedprice,
  resetaverage,
  finishaverage,
  computeaverage,
  createMissingAttr,
  resetSets,
  setOneTimeAndPriceSet,
} from '../../lib/partutils';
import { store } from '../../lib/nrstore';

const { pricinglib, utils } = pricingUtilities;
const {
  pmp: priorityfetch, crc: crcdefs, methods,
} = pricingMethods;
const {
  findactualdate, findpricedate, getattrValue, getattr, mkEmptyRule,
  getValueFromRaw, getPartValue, displayStatus, getAttrSet, isZero,
  getOne,
} = utils;
const {
  mklib, smoothingRate, smooth,
  getPartAltCurrency, getPartCurrency,
  lookupRate,
  computestdmargin,
  computeweightedmargin,
  findCurrentRule,
} = pricinglib;
const sactualdateindex = Symbol.for('actualdateindex');

function recomputeall(state) {
  resetSets(state);
  store.parts.forEach((part) => {
    state.rule.func(part, true, true);
  });
}

function fixed2print(fixed, digit = 2) {
  return Number(fixed).toFixed(digit).toString();
}
function display(state, {
  part, // the orig part
  attr, // attribute name
  raw, // actual value if provided
  locale,
  settings,
}) {
  const digits = (state.defs.defs2[attr].settings || {}).digits || 2;

  const res = {};
  res.html = '';
  let data = raw;
  const partId = part.id.v;
  if (raw === undefined) {
    const pset = getAttrSet(part, attr, state.priceset, state.rule.definition.common.pmpattr, settings);
    data = getattr(part, attr, pset);
  }
  if (!data || data === null) return res;
  if (data.v !== undefined) {
    res.v = JSON.parse(JSON.stringify(data.v));
  }
  res.type = data.type;

  const history = (state.defs.defs2[attr].settings || {}).history || false;
  if (history || data.name === 'price' || data.name === 'fullcostingratio') {
    res.id = data.id;
  }

  switch (data.type) {
    case 'date':
      res.html = `<span style="font-size: 0.3em;">${(new Date(data)).toLocaleDateString()}</span>`;
      break;
    case 'number':
      if (data.v && data.v.fixed) {
        if (data.v.unit === '%' && Number.isFinite(data.v.number)) {
          res.html = `<span style="width:25px; float: right;">${data.v.unit ? data.v.unit : '&nbsp;'}</span><span style="float: right; margin-right: 5px;">${fixed2print(data.v.number * 100, digits)}</span>`;
          break;
        } else if (Number.isFinite(data.v.number)) {
          res.html = `<span style="width:25px; float: right;">${data.v.unit ? data.v.unit : '&nbsp;'}</span><span style="float: right; margin-right: 5px;">${fixed2print(data.v.fixed, digits)}</span>`;
          break;
        }
      } else if (Number.isFinite(data.v)) {
        res.html = `<span style="width:25px; float: right;">&nbsp;</span><span style="float: right; margin-right: 5px;">${data.v}</span>`;
      }
      break;
    case 'amount':
    case 'pricing':
      if (data.v && data.v.fixed) {
        res.html = `<span style="width:25px; float: right;">${data.v.unit ? data.v.unit : '&nbsp;'}</span><span style="float: right; margin-right: 5px;">${displayStatus(data.syncstatus)} ${data[Symbol.for('notlast')] ? '🕜 ' : ''}${fixed2print(data.v.fixed, digits)}</span>`;
      } else if (Number.isInteger(data.v)) {
        res.html = `<span style="width:25px; float: right;">&nbsp;</span><span style="float: right; margin-right: 5px;">${displayStatus(data.syncstatus)} ${data.v}</span>`;
      }
      break;
    case 'boolean':
      if (data.v) {
        res.html = `${data.v ? '✓' : ''}`;
      }
      break;
    case 'string':
      if (data.name === 'name') {
        res.html = `<span style="cursor: alias; font-size: 1em; text-decoration: none;" ondblclick="window.open('/products/${partId}', '_blank');">${data.v}</span>`;
      } else if (data.name === 'replaced-by') {
        res.html = `<span style="cursor: alias; font-size: 1em; text-decoration: none;" ondblclick="window.open('/products/${data.v}', '_blank');">${data.v}</span>`;
      } else {
        res.html = data.v;
      }
      break;
    case 'localizedstring':
      res.html = `<div class="tooltip" title="${localization.methods.localized(data.v, locale)}">${localization.methods.localized(data.v, locale)}</div>`;
      break;
    case 'collection':
      res.html = data.v;
      break;
    case 'interval':
    {
      if (data.v) {
        const from = new Date(data.v.from).toLocaleDateString();
        const to = data.v.to ? new Date(data.v.to).toLocaleDateString() : '∞';
        res.html = `<span style="font-size: 0.85em;">${from} - ${to}</span>`;
      }
      break;
    }
    default:
      console.error('type inconnu', data.type);
      res.html = `D ${data.type} ${JSON.stringify(data)}`;
  }

  if (state.defs.defs2[attr] && state.defs.defs2[attr].groupable && state.defs.defs2[attr].groupable === true) {
    let tmp = slugify(data.v);
    tmp = parseInt(md5(tmp).substring(0, 2), 16) % 360;
    res.color = `hsl(${tmp}, 100%, 50%)`;
  }

  return res;
}

function refactorAttrs(state, {
  part, // the orig part
  attrs, // an array of attributes to be updated
  idx, // the local index
  locale,
  settings,
}) {
  // console.log(attrs);
  if (!attrs || attrs.length < 1) return;
  state.partsPage[idx][Symbol.for('variationcss')] = part[Symbol.for('variationcss')];
  attrs.forEach((attr) => {
    if (attr.name === 'validatedprice') {
      state.partsPage[idx].validatedprice.v = JSON.parse(JSON.stringify(attr.v));
      return;
    }
    // state.partsPage[idx].blocs.
    state.rule.attrslayout.forEach((bloc, bid) => {
      const gid = bloc.findIndex((group) => state.defs.defs2[attr.name].root === group.id);
      if (gid > -1) {
        // console.log(`Update ${attr.name}`);
        state.partsPage[idx].blocs[bid].groups[gid][attr.name] = display(state, {
          part, attr: attr.name, raw: attr, locale, settings,
        });
      }
    });
  });
}

function refactorPart(state, data) {
  const { part, locale, settings } = data;
  const tmpPart = JSON.parse(JSON.stringify({
    name: part.name,
    pictures: part.pictures,
    validatedprice: part.validatedprice,
    comment: part.comment,
    id: part.id.v,
    nridx: part[Symbol.for('partindex')],
  }));
  tmpPart[Symbol.for('variationcss')] = part[Symbol.for('variationcss')];
  tmpPart.blocs = state.rule.attrslayout.map((bloc_) => {
    const tmpBloc = {
      groups: [],
    };
    tmpBloc.groups = bloc_.map((group_) => group_.attrs.reduce((_, attr) => {
      _[attr.name] = display(state, {
        part,
        attr: attr.name,
        locale,
        settings,
      });
      return _;
    }, {}));
    return tmpBloc;
  });

  return tmpPart;
}

export const setPartPage = (state, data) => {
  const {
    locale,
    settings, pagesize, currentPage,
  } = data;

  let slice = store.parts;
  /* Search by name filter */
  if (state.searchFilter !== '') {
    const searchWord = state.searchFilter.toLowerCase();
    slice = slice.filter((item) => {
      let result = null;
      if (state.searchType.includes('ref')) {
        result = item.name.v.toLowerCase().includes(searchWord);
      }
      if (state.searchType.includes('des')) {
        result = localization.methods.localized(item.label.v, locale, null).toLowerCase().includes(searchWord) || result;
      }
      return result;
    });
  }

  /* Search by group filter */
  if (state.partsFilter.groupable && state.partsFilter.mode !== 'disabled') {
    state.searchGoToID = 0;
    state.searchGoToRealID = 0;
    state.searchGoToSize = 0;
    state.searchGoTo = '';

    if (state.partsFilter.mode === 'same') {
      slice = slice.filter((p) => getattrValue(p, state.partsFilter.groupable.name) === state.partsFilter.groupable.v);
    } else if (state.partsFilter.mode === 'different') {
      slice = slice.filter((p) => getattrValue(p, state.partsFilter.groupable.name) !== state.partsFilter.groupable.v);
      slice.unshift(store.parts[state.partsFilter.selected]);
    }
  }

  let start = (currentPage - 1) * pagesize;
  let length = (currentPage - 1) * pagesize + pagesize;
  state.partsLength = slice.length;
  state.currentPage = currentPage;

  /* Search by name goto */
  if (state.searchGoTo !== '') {
    const searchWord = state.searchGoTo.toLowerCase();

    // count the number of items matching the search
    const count = slice.filter((item) => {
      let result = null;
      if (state.searchType.includes('ref')) {
        result = item.name.v.toLowerCase().includes(searchWord);
      }
      if (state.searchType.includes('des')) {
        result = localization.methods.localized(item.label.v, locale, null).toLowerCase().includes(searchWord) || result;
      }
      return result;

      /*
      let lookIn = null;
      if (state.searchType === 'ref') {
        lookIn = item.name.v.toLowerCase();
      } else if (state.searchType === 'des') {
        lookIn = localization.methods.localized(item.label.v, locale).toLowerCase();
      }
      return lookIn.includes(searchWord); */
    }).length;
    state.searchGoToSize = count;

    // find the [searchGoToID] occurence of the search
    let findCount = 1;
    const idx = slice.findIndex((item, i) => {
      let result = null;
      if (state.searchType.includes('ref')) {
        result = item.name.v.toLowerCase().includes(searchWord);
      }
      if (state.searchType.includes('des')) {
        result = localization.methods.localized(item.label.v, locale, null).toLowerCase().includes(searchWord) || result;
      }

      if (findCount === state.searchGoToID && result) return true;
      if (result) findCount += 1;

      return false;

      /*
      let lookIn = null;
      if (state.searchType === 'ref') {
        lookIn = item.name.v.toLowerCase();
      } else if (state.searchType === 'des') {
        lookIn = localization.methods.localized(item.label.v, locale).toLowerCase();
      }

      if (findCount === state.searchGoToID && lookIn.includes(searchWord)) return true;
      if (lookIn.includes(searchWord)) {
        findCount += 1;
      }
      return false;
      */
    });

    if (idx > -1) {
      state.currentPage = Math.floor(idx / pagesize) + 1;
      start = (state.currentPage - 1) * pagesize;
      length = (state.currentPage - 1) * pagesize + pagesize;
      state.searchGoToRealID = idx - pagesize * (state.currentPage - 1);
    }
  }

  /* Check the current page position */
  if (currentPage > Math.floor(state.partsLength / pagesize) + 1) {
    state.currentPage = 1;
  }

  /* Slice the and set the array */
  start = (state.currentPage - 1) * pagesize;
  length = (state.currentPage - 1) * pagesize + pagesize;
  slice = slice.slice(start, length);
  state.partsPage = slice.map((part) => refactorPart(state, { part, locale, settings }));
  setTimeout(() => {
    state.alreadyrendered = false;
  }, 200);
};

function incr(a, b = 1) {
  return a + b;
}

function decr(a, b = 1) {
  return a - b;
}

function addVariation(state, part, op = incr) {
  const {
    priceattr,
    priceset, // is that correct probably not : FIXME
    salesattr,
    proposedattr,
    rawproposedattr,
    pmpattr,
    pmpset,
    marginattr,
    vmarginattr,
    alertattr,
    purchaseattr,
  } = {
    ...state.rule.definition.common,
    priceset: state.priceset,
    proposedattr: 'proposedprice',
    rawproposedattr: 'rawproposedprice',
    pmpset: (part[state.rule.definition.common.pmpattr] || {})[Symbol.for('actualset')],
    marginattr: 'margin',
    vmarginattr: 'vmargin',
    alertattr: 'purchasealert',
    purchaseattr: ((state.rule.definition.common || {}).margins || {}).basis, // FIXME pmpattr+pmpset were done before selectedpmp
  };
  let price = getPartValue(part, priceattr, priceset);
  const alert = getPartValue(part, alertattr);
  let proposedprice = getPartValue(part, proposedattr, priceset);
  let rawproposedprice = getPartValue(part, rawproposedattr, priceset);
  if (price === undefined || proposedprice === undefined) return;
  price = Math.round(price * 100) / 100;
  proposedprice = Math.round(proposedprice * 100) / 100;
  rawproposedprice = Math.round(rawproposedprice * 100) / 100;
  if (alert) state.stats.purchasealerts = op(state.stats.purchasealerts);
  if (rawproposedprice < 0) state.stats.negativeprices = op(state.stats.negativeprices);
  if (proposedprice > price) {
    state.stats.variations[2] = op(state.stats.variations[2]);
    part[Symbol.for('variationcss')] = 'lg-variation-2';
  } else if (proposedprice < price) {
    state.stats.variations[0] = op(state.stats.variations[0]);
    part[Symbol.for('variationcss')] = 'lg-variation-0';
  } else {
    state.stats.variations[1] = op(state.stats.variations[1]);
    part[Symbol.for('variationcss')] = 'lg-variation-1';
  }
  if (part[marginattr] && part[marginattr][priceset] && part[marginattr][priceset].length) {
    state.stats.margins.sum = op(state.stats.margins.sum, part[marginattr][priceset][0].v.number);
    // eslint-disable-next-line no-plusplus
    state.stats.margins.count = op(state.stats.margins.count, 1);
  }
  if (part[vmarginattr] && part[vmarginattr][priceset] && part[vmarginattr][priceset].length) {
    state.stats.margins.sumv = op(state.stats.margins.sumv, part[vmarginattr][priceset][0].v.number);
    // eslint-disable-next-line no-plusplus
    state.stats.margins.countv = op(state.stats.margins.countv, 1);
  }
  if (part[salesattr] && part[salesattr].DEFAULT && part[salesattr].DEFAULT.length) {
    const qty = part[salesattr].DEFAULT[0].v.number;
    state.stats.turnover = op(state.stats.turnover, proposedprice * qty);
    if (purchaseattr && purchaseattr !== 'selectedpmp') { // FIXME : compat mode
      if (part[purchaseattr] && part[purchaseattr][priceset] && part[purchaseattr][priceset].length) {
        const purchasingcost = Math.round(part[purchaseattr][priceset][0].v.number * 100) / 100;
        state.stats.purchase = op(state.stats.purchase, purchasingcost * qty);
      }
    } else if (part[pmpattr] && part[pmpattr][pmpset] && part[pmpattr][pmpset].length && part[priceattr][priceset][Symbol.for('actualdateindex')] >= 0) {
      const purchasingcost = Math.round(part[pmpattr][pmpset][part[pmpattr][pmpset][Symbol.for('actualdateindex')]].v.number * 100) / 100;
      state.stats.purchase = op(state.stats.purchase, purchasingcost * qty);
    }
  }
}

export const setFamily = (state, data) => {
  state.family = data;
};
export const setNonPriced = (state, nonpriced) => {
  state.nonpriced = nonpriced;
};

export const setSlaves = (state, slaves) => {
  state.slaves = slaves;
};

export const setSubNoms = (state, subnoms) => {
  state.subnoms = subnoms;
};

export const clearPricing = (state) => {
  state.nonpriced = [];
  state.subnoms = [];
  state.partsPage = [];
  store.parts = [];
  state.backlog = [];
};
function mkPartStatusNotObsolete(part) {
  return {
    name: 'partstatus',
    type: 'boolean',
    fuzzy: true,
    t: '(,)',
    v: false,
  };
}
export const updatePrice = (state, {
  data,
  one,
  locale,
  settings,
}) => {
  const part = store.parts.find((p) => p.id.v === data.id.v);

  let ridx2 = -1;
  if (data.fullcostingratio) {
    ridx2 = findactualdate((data.fullcostingratio || {})[state.rule.tag], state.currenttime);
    data.fullcostingratio[state.rule.tag][Symbol.for('actualdateindex')] = ridx2;
    part.fullcostingratio = data.fullcostingratio;
  }

  const ridx = findactualdate((data.price || {})[state.rule.tag], state.currenttime);
  data.price[state.rule.tag][Symbol.for('actualdateindex')] = ridx;
  part.price = data.price;
  part.pricestatus = data.pricestatus;
  part.partstatus = data.partstatus === undefined ? mkPartStatusNotObsolete(data) : data.partstatus;
  part.validatedprice = mkemptyvalidatedprice(state, part, one);
  if (data.classification) part.classification = data.classification;
  setOneTimeAndPriceSet(part, state.priceset);
  const attrs = ['fullcostingratio', 'price', 'partstatus', 'pricestatus', 'classification', 'validatedprice', 'validationdate'].reduce((_, name) => {
    if (!part[name]) return _;
    if (name === 'price') {
      _.push(part[name][state.rule.tag][ridx]);
    } else if (name === 'fullcostingratio' && ridx2 > -1) {
      _.push(part[name][state.rule.tag][ridx2]);
    } else {
      _.push(part[name]);
    }
    return _;
  }, []);
  // FIXME : we should update
  const sidx = Symbol.for('partindex');
  const idx = state.partsPage.findIndex((p) => p.nridx === part[sidx]); // FIXME there is a better way
  if (idx > -1) {
    refactorAttrs(state, {
      part,
      attrs,
      idx,
      locale,
      settings,
    });
  }
};

function toObject(a, key = 'id') {
  return Object.assign({}, ...a.map((item) => ({ [item[key]]: item })));
}

function pushingroup(group, def, sets) {
  const fixed = sets.sets[def.root].fixed ? 0 : 1;
  const index = group[fixed].findIndex((item) => (item.id === def.root));
  if (index < 0) {
    group[fixed].push({ id: def.root, attrs: [def.name] });
  } else {
    group[fixed][index].attrs.push(def.name);
  }
  return group;
}

// 8
export const setAttribute = (state, definition) => {
  state.defs.defs[definition.id] = definition;
  state.defs.defs2[definition.name] = definition;
  if (!state.defs.order.includes(definition.name)) {
    state.defs.order.push(definition.name);
    state.defs.uuids.push(definition.id);
    pushingroup(state.defs.grouped, definition, state.sets);
  }
};

export const setAttributes = (state, { rawsets, rawdefinitions, alldimensions }) => {
  const sets = {
    sets: toObject(rawsets),
    order: rawsets.map((d) => d.name),
  };
  const defs = {
    defs: toObject(rawdefinitions),
    defs2: toObject(rawdefinitions, 'name'),
    order: rawdefinitions.map((d) => d.name),
    uuids: rawdefinitions.map((d) => d.id),
    grouped: rawdefinitions.reduce((group, def) => pushingroup(group, def, sets), [[], []]),
  };

  state.alldimensions = alldimensions;

  /*
  if (alldimensions) {
    const allsets = Object.values(sets.sets);
    const did = (allsets.find((s) => (s.name === 'dimensions')) || {}).id;
    const { id } = (allsets.find((s) => (s.name === 'alldimensions')) || {});
    if (did && id) {
      defs.grouped[1].push({
        attrs: [...(defs.grouped[1].find((b) => (b.id === did)) || {}).attrs],
        id,
        closed: true,
        nofilter: true,
      });
    }
  } */

  state.loaded = true;
  state.defs = defs;
  state.sets = sets;
};

export const setSets = (state, data) => {
  state.sets = data;
};
export const setLoaded2 = (state, data) => {
  state.loaded2 = true;
};

export const showComment = (state, data) => {
  state.rule.showcomment = !!data;
};

export const setComment = (state, data) => {
  state.rule.comment = data;
};

export const setMethodOverride = (state, data) => {
  Vue.set(state.rule.definition, 'override', data);
  recomputeall(state);
};
export const setProxyFoo = (state, data) => {
  Vue.set(state.rule.definition, 'proxyfoo', data);
  recomputeall(state);
};

export const setKitBasedOn = (state, data) => {
  Vue.set(state.rule.definition, 'kitbasedon', data);
  recomputeall(state);
};

function friendlyfromts(ts, locale) {
  const now = (new Date()).getTime();
  const d = now - ts;
  if (d < 5) return 'few seconds ago';
  if (d < 5 * 60) return 'few minutes ago';
  if (d < 3600) return `${Math.floor(d / 60)}min ago`;
  if (d < 3800) return 'about 1 hour ago';
  return `on ${(new Date(ts).toLocaleDateString(locale, {
    weekday: 'short', year: 'numeric', month: 'long', day: 'numeric',
  }))}`;
}
function fromIsoDateString(d) {
  const [yyyy, mm, dd] = d.split('-');
  return new Date(yyyy, mm - 1, dd);
}
// setters

// WARNING works for rule pivot and attributes not on parts attributes

function costCheck(rule, part, attr, op, v1, v2) {
  let res = false;
  if (attr === 'none') return 'none';

  let v = getattrValue(part, attr);
  if (!v && attr === rule.definition.common.pmpattr) {
    const purchasingcostset = (part[attr] || {})[Symbol.for('actualset')];
    const id = part[attr][purchasingcostset][Symbol.for('actualdateindex')];
    const purchasingcost = getPartValue(part, rule.definition.common.pmpattr, purchasingcostset);
    v = purchasingcost;
  }

  switch (op) {
    case '=':
      if (v === v1) res = true;
      break;
    case '<':
      if (v < v1) res = true;
      break;
    case '>':
      if (v > v1) res = true;
      break;
    case 'entre':
      if (v > v1 && v < v2) res = true;
      break;
    case 'starts with':
      if (v.startsWith(v1)) res = true;
      break;
    case 'contains':
      if (v.includes(v1)) res = true;
      break;
    case 'true':
      if (v === true) res = true;
      break;
    case 'false':
      if (v === false) res = true;
      break;
    case 'automatique':
      if (v > v1 && v < v2) res = true;
      break;
    default:
      res = false;
  }
  return res;
}

function preparerule(state, data, def, locale, settings) {
  if (!data.similars) {
    data.similars = {
      max: 10,
      closests: true,
      sellers: true,
      includes: [],
      hasincludes: false,
      identical: false,
    };
  }
  if (!data.comment) data.comment = '';
  if (!data.definition.highlightedParts) data.definition.highlightedParts = [null, null, null];

  if (!data.definition.implstrategy) {
    data.definition.implstrategy = settings.implstrategy;
  }

  if (data.t) data.t = fromIsoDateString(data.t); else data.t = new Date();
  if (data.t2) data.t2 = fromIsoDateString(data.t2);

  if (!data.definition.attributes) data.definition.attributes = [];
  data.definition.attributes = data.definition.attributes.filter((attr) => !!def[attr.name]);

  // eslint-disable-next-line no-use-before-define
  updateAttrsLayout(state, data, state.defs.defs2);

  const { lib } = state;

  data.costPlusFactory = lib.costPlusFactory;
  if (data.method === 'cost+') {
    data.definition.costPlusFunc = lib.costPlusFactory(data.definition.costplus, def);
  }
  data.rawfunc = methods[data.method].ctr(data, def, lib, settings).price;
  data.clearcache = methods[data.method].ctr(data, def, lib, settings).clear;
  data.func = (part, updateparts, incremental, ...rest) => {
    if (state.single && methods[data.method].kit && part.id.v !== state.singlePart.id.v) return {};
    let result = data.rules.map((r, i) => {
      const res = lib.getRuleFromRegistry(r.id);
      // if (!res) return undefined;
      if (res && res.id !== data.id && state.computed[res.tag]) return undefined;
      const computationresults = ((r.id === undefined && data.id === undefined) || res.id === data.id)
        ? data.rawfunc(part, updateparts, store.parts, state.currenttime, store.externalmasters, store.altparts, ...rest)
        : res.func(part, true, false, ...rest);
      if (settings.classification && state.defs.defs2.classification) {
        // we need to check for classification
        const classification = lib.computeClassification(part, def, data, methods, settings);
        computationresults.results.push(lib.setPartClassification(part, classification, state.defs.defs2.classification.set));
      }
      computationresults.results.push();
      return computationresults;
    });
    result = result[result.length - 1];

    if (incremental) {
      // TODO : we must do someting
      const sidx = Symbol.for('partindex');
      const idx = state.partsPage.findIndex((p) => p.nridx === part[sidx]); // FIXME there is a better way
      if (idx > -1) {
        refactorAttrs(state, {
          part, attrs: result.results, idx, locale, settings,
        });
      }
    }
    addVariation(state, part, ...rest);
    return result;
  };
  if (['vp', 'cpt'].includes(data.method) && (!(data.definition && data.definition.pivottype))) {
    data.definition.pivottype = 'one';
  }
  if (!data.definition.multipivot) {
    data.definition.multipivot = settings.multipivot;
  }

  if (!data.definition.multipivot.date) {
    data.definition.multipivot.date = new Date();
  } else {
    data.definition.multipivot.date = new Date(data.definition.multipivot.date);
  }
  if (!data.definition.multipivot.attribute) {
    data.definition.multipivot.attribute = 'price';
  }

  if (!data.definition.multipivot2) {
    data.definition.multipivot2 = settings.multipivot2;
  }
  if (!data.definition.multipivot2.pricedate) {
    data.definition.multipivot2.pricedate = new Date();
  } else {
    data.definition.multipivot2.pricedate = new Date(data.definition.multipivot2.pricedate);
  }
  data.definition.multipivot2.levels = {
    ...{ manuallf: 0, manualexpected: 0 },
    ...data.definition.multipivot2.levels,
  };
  data.strategyrate = [];
  (data.definition.implstrategy || []).forEach((strategy, index) => {
    if (strategy.type === 'progressive') {
      const treedef = data.definition.implstrategy[index].thresholdtree || [{ decision: 0.2 }];
      data.strategyrate[index] = state.lib.costPlusFactory(treedef, state.defs.defs2);
      if (!data.definition.implstrategy[index].reference) {
        data.definition.implstrategy[index].reference = {
          attr: 'price',
          date: new Date(),
        };
      }
    }
  });
  if (data.definition.common && !data.definition.common.averagediscount) {
    data.definition.common.averagediscount = 0;
  }

  if (data.definition.common && !data.definition.common.featureimpact) {
    data.definition.common.featureimpact = 'multiplicative';
  }

  if (data.definition.common && !data.definition.common.competitionlevel) {
    data.definition.common.competitionlevel = 'captive';
  }

  if (data.definition.common && !data.definition.common.fullcosting) {
    data.definition.common.fullcosting = JSON.parse(JSON.stringify(settings.fullcosting));
  }

  if (data.definition.vdiscounts === undefined) data.definition.vdiscounts = [];
  if (data.definition.autocrc === undefined && settings.autocrc) {
    data.definition.autocrc = JSON.parse(JSON.stringify(settings.autocrc));
    if (!data.definition.autocrc.enabled) data.definition.autocrc.enabled = false;
  } else if (data.definition.autocrc && settings.autocrc) {
    data.definition.autocrc = {
      ...JSON.parse(JSON.stringify(settings.autocrc)),
      ...data.definition.autocrc,
    };
  }
  data.definition.smoothingRate = smoothingRate;
  (data.definition.smoothing || []).forEach((attr) => {
    attr.cache = {}; // clear the cache whatever might have been saved , see e5d13ab379a0e5de1c1f7651b8d8430d86f7788f
    attr.func = smooth;
  });

  data.active = !!data.activated_at;
  data.last_modified = new Date(data.updated_at);
  return data;
}

export const clearRuleStore = (state, defaultpriceset) => {
  state.rulestore = [];
  state.ruleregistry = {};
  state.computed[defaultpriceset] = true;
};
export const setRates = (state, rates) => {
  Object.freeze(rates);
  state.rates = rates;
};

function findRulePath(state, rule = state.rule) {
  const copy = { id: rule.id, tag: rule.tag, method: rule.method };
  if (!rule.parent) return [copy];
  const parent = state.rulestore.find((r) => (r.tag === rule.parent));
  if (parent === undefined) return [copy];
  return [copy, ...findRulePath(state, parent)];
}

export const setRule = (state, { rule, locale, settings }, nochange) => {
  let cachedrule = rule.id ? state.rulestore.find((r) => (r.id === rule.id)) : undefined;
  if (cachedrule === undefined) {
    cachedrule = preparerule(state, rule, state.defs.defs2, locale, settings);
    cachedrule.rules = findRulePath(state, cachedrule).reverse();
    if (rule.id) {
      state.ruleregistry[cachedrule.id] = state.rulestore.length;
      state.rulestore.push(cachedrule);
    }
  }
  if (!nochange) {
    state.priceset = cachedrule.tag;
    state.rule = cachedrule;
  }
};

export const setRule2 = (state, data) => {
  // Vue.set(state, 'rule', data);
  setRule(state, data);
  resetSets(state);
  const references = (state.rule.definition.pivot || []).map((a) => a.name);
  resetaverage(references, state.averagereferences);
  const actualdate = state.currenttime;
  store.parts.forEach((part) => {
    createMissingAttr(part, state.rule.definition.attributes || [], state.defs.defs2, true);
    const s = Symbol.for('actualdateindex');
    if (part.fullcostingratio) {
      Vue.set(part.fullcostingratio.DEFAULT, s, findactualdate(part.fullcostingratio.DEFAULT, actualdate));
    }
    if (part.pricingmethod) {
      Vue.set(part.pricingmethod.DEFAULT, s, findactualdate(part.pricingmethod.DEFAULT, actualdate));
    }
    if (part.price && part.price[state.rule.tag]) {
      const idx = findactualdate(part.price[state.rule.tag], actualdate);
      Vue.set(part.price[state.rule.tag], s, idx);
    }
    if (state.rule.definition.common && state.rule.definition.common.pmpattr && part[state.rule.definition.common.pmpattr]) {
      const { set, idx } = state.lib.pmpset(part, state.rule.definition.common.pmpattr, state.rule.definition, actualdate, findactualdate);
      part[state.rule.definition.common.pmpattr][Symbol.for('actualset')] = set;
      if (idx !== -1) Vue.set(part[state.rule.definition.common.pmpattr][set], s, idx);
    }
    state.rule.func(part, true, false);
    const sidx = Symbol.for('partindex');
    const idx = state.partsPage.findIndex((p) => p.nridx === part[sidx]);
    if (idx > -1) state.partsPage[idx] = refactorPart(state, { part, locale: data.locale, settings: data.settings });
    computeaverage(references, state.averagereferences, part);
  });
  finishaverage(references, state.averagereferences);
};

export const loadRules = (state, { rules, locale, settings }) => {
  rules.sort((a, b) => {
    if (!a.parent && b.parent) return -1;
    if (a.parent && !b.parent) return +1;
    if (a.parent !== b.parent) return (a.parent < b.parent) - (b.parent < a.parent);
    return (a.tag < b.tag) - (b.tag < a.tag);
  }).forEach((rule) => setRule(state, { rule, locale, settings }, true));
};

export const pushinBacklog = (state, data) => {
  state.backlog = [...state.backlog.filter((m) => (m.attr !== data.attr || m.part !== data.part)), data];
};

function setActivable(state) {
  state.activable = store.parts.filter((p) => (p.validatedprice && p.validatedprice.v.fixed));
}

export const updateAttr = (state, {
  nridx, // full array idx
  idx, // page idx
  name, // attribute name
  value, // raw input value
  unit, // unit if any
  one,
  locale,
  settings,
}) => {
  // 1. first we update the attribute value of the part in the full array
  const part = store.parts[nridx];
  if (part === undefined) return;
  const a = part[name];
  if (a === undefined) return;

  switch (a.type) {
    case 'amount':
      a.v = {
        fixed: value.toString(),
        number: value === '' ? undefined : Number(value),
        unit,
        qty: getOne(part, state.rule.priceset, one),
      };
      break;
    case 'number':
      a.v = { fixed: value.toString(), number: Number(value), unit: '' };
      break;
    case 'boolean':
      a.v = !!value;
      break;
    default:
      console.log('problem', a.type);
  }

  // 2. then we recompute everything that must be in the part
  if (a.name === 'validatedprice') {
    setActivable(state);
  } else {
    addVariation(state, part, decr);
    state.rule.func(part, true, true); // last true means we will update also the pricing
    //                                    attributes in the reactive page
  }
  // then it's time for us to update attribute in the reactive page
  refactorAttrs(state, {
    part,
    attrs: [{ name, type: a.type, v: a.v }],
    idx,
    locale,
    settings,
  });
  // state.partsPage[idx] = refactorPart(state, { part, locale });
};

export const updateRulePivot = (state, data) => {
  const { rulepart, name, v } = data;
  const index = state.rule.definition[rulepart].findIndex((item) => item.name === name);
  if (index < 0) return;
  // const ref = Object.assign({}, );
  const type = rulepart === 'pivot' ? state.defs.defs2[name].type : state.rule.definition[rulepart][index].type;
  switch (type) {
    case 'number':
    case 'amount':
    case 'pricing':
      state.rule.definition[rulepart][index].v.fixed = v;
      state.rule.definition[rulepart][index].v.number = Number(v);
      break;
    case 'rate':
      state.rule.definition[rulepart][index].v = Number(v);
      break;
    default:
  }
  recomputeall(state);
};

export const setEmpty = (state, data) => {
  state.emptyrule = JSON.stringify(data);
};

export const updateRuleCommon = (state, data) => {
  const actualdate = state.currenttime;
  const {
    name,
    value,
    locale,
    settings,
  } = data;
  if (name === 'pmp' || name === 'parentpriceset') {
    state.rule.definition.common[name] = value;
  } else {
    state.rule.definition.common[name] = Number(value);
  }
  if (name === 'upgrade') return;
  resetSets(state);
  store.parts.forEach((part) => {
    if (name === 'priceset') {
      if (part.price) {
        part.price[state.rule.tag][Symbol.for('actualdateindex')] = findactualdate(part.price[state.rule.tag], actualdate);
      }
    } else if (name === 'pmp') {
      if (part[state.rule.definition.common.pmpattr]) {
        const { set, idx } = state.lib.pmpset(part, state.rule.definition.common.pmpattr, state.rule.definition, actualdate, findactualdate);
        if (part[state.rule.definition.common.pmpattr][set] && idx > -1) {
          part[state.rule.definition.common.pmpattr][Symbol.for('actualset')] = set;
          part[state.rule.definition.common.pmpattr][set][Symbol.for('actualdateindex')] = idx;
        }
      }
    }
    state.rule.func(part, true, name !== 'pmp'); // pmp => no refactorAttrs
    if (name === 'pmp') {
      const sidx = Symbol.for('partindex');
      const idx = state.partsPage.findIndex((p) => p.nridx === part[sidx]); // FIXME there is a better way
      if (idx > -1) state.partsPage[idx] = refactorPart(state, { part, locale, settings });
    }
  });
};

export const setValidatedPrice = (state, data) => {
  const {
    nridx,
    idx,
    name, // this should be 'proposedprice' or it's a bug.
    one,
    locale,
    settings,
  } = data;
  const p = store.parts[nridx];
  const { priceset } = state;
  if (p === undefined) return;
  if (!(p[name] && p[name][priceset] && p[name][priceset].length && !Number.isNaN(p[name][priceset][0].v.number))) return;
  // let's set the orig part
  p.validatedprice.v = { ...p[name][priceset][0].v };
  p.validatedprice.v.qty = getOne(p, priceset, one); // this is wrong.

  // and update the page one
  refactorAttrs(state, {
    part: p,
    attrs: [{ name: 'validatedprice', type: p.validatedprice.type, v: p.validatedprice.v }],
    idx,
    locale,
    settings,
  });
  setActivable(state);
};

export const approvePrices = (state, {
  what, one, locale, settings,
}) => {
  const { priceattr } = state.rule.definition.common;
  const { priceset } = state;

  store.parts.forEach((part) => {
    let price;
    const thereisaprice = (part[priceattr] && part[priceattr][priceset]
      && part[priceattr][priceset].length
      && (part[priceattr][priceset][Symbol.for('actualdateindex')] >= 0));
    if (what === 'new') {
      if (thereisaprice) return;
    } else if (what !== 'all' && what !== 'none') {
      if (thereisaprice) {
        const idx = part[priceattr][priceset][Symbol.for('actualdateindex')];
        price = part[priceattr][priceset][idx].v;
      }
    }
    // no proposed price and we are not resetting.
    if (!(part.proposedprice && part.proposedprice[priceset] && part.proposedprice[priceset].length
      && !Number.isNaN(part.proposedprice[priceset][0].v.number)) && what !== 'none') return;

    switch (what) {
      case 'new':
        break;
      case 'up':
        if (parseFloat(part.proposedprice[priceset][0].v.number) <= parseFloat(price.fixed)) return;
        break;
      case 'down':
        if (parseFloat(part.proposedprice[priceset][0].v.number) >= parseFloat(price.fixed)) return;
        break;
      case 'none':
      case 'all':
        break;
      default:
        return;
    }

    // let's set the orig part
    if (what === 'none') {
      part.validatedprice.v = { fixed: '', unit: price ? price.unit : part.validatedprice.v.unit };
    } else {
      part.validatedprice.v = { ...part.proposedprice[priceset][0].v };
      part.validatedprice.v.qty = price && price.qty ? price.qty : getOne(part, state.rule.priceset, one); // this is wrong I gess
    }

    const sidx = Symbol.for('partindex');
    const idx = state.partsPage.findIndex((p) => p.nridx === part[sidx]); // FIXME there is a better way
    if (idx > -1) {
      refactorAttrs(state, {
        part,
        attrs: [{ name: 'validatedprice', type: part.validatedprice.type, v: part.validatedprice.v }],
        idx,
        locale,
        settings,
      });
    }
  });
  setActivable(state);
};

function mkemptyvalue(type, unit) {
  switch (type) {
    case 'rate':
      return 1;
    case 'number':
    case 'amount':
    case 'pricing':
      return { fixed: '0', number: 0, unit };
    case 'boolean':
      return false;
    default:
      console.log('Unsupported type');
  }
  return false;
}

export const addSmoothingAttribute = (state, data) => {
  const truncated = store.parts.filter((p) => p[data.attrName]);
  if (truncated.length < 1 || !truncated[0][data.attrName]) {
    data.succes = false;
    return;
  }

  const minmax = truncated.reduce((acc, val) => {
    const attr = getattrValue(val, data.attrName);
    acc[0] = (acc[0] === undefined || attr < acc[0]) ? attr : acc[0];
    acc[1] = (acc[1] === undefined || attr > acc[1]) ? attr : acc[1];
    return acc;
  }, []);

  if (minmax['0'] === minmax['1']) {
    data.succes = false;
    return;
  }

  const n = {
    attribute: data.attrName,
    min: minmax['0'],
    max: minmax['1'],
    path: '',
    points: [{ x: 0, y: 1 }, { x: minmax['0'], y: 1 }, { x: minmax['1'], y: 1 }, { x: '∞', y: 1 }],
    func: smooth,
  };

  state.rule.definition.smoothing.push(n);
  data.succes = true;
};

export const updateSmoothingAttribute = (state, data) => {
  // Update array
  state.rule.definition.smoothing[data.id].points = data.nv;

  // Clean cache & Update function
  delete state.rule.definition.smoothing[data.id].cache;
  state.rule.definition.smoothing[data.id].func = smooth;

  // update everything
  recomputeall(state);
};
export const updateVDiscounts = (state, data) => {
  state.rule.definition.vdiscounts = data;
};

export const updateMultipivot = (state, { payload }) => {
  const {
    field, value, index, subfield,
  } = payload;
  switch (field) {
    case 'dimension':
    case 'level':
    case 'attribute':
    case 'date':
      state.rule.definition.multipivot[field] = value;
      break;
    case 'boundaries':
      state.rule.definition.multipivot[field][index][subfield] = value;
      break;
    default:
  }
  recomputeall(state);
};

export const updateMultipivot2 = (state, { payload, settings }) => {
  const {
    field, value, index, subfield,
  } = payload;
  switch (field) {
    case 'lfthreshold':
      {
        let v = Number(value);
        if (Number.isNaN(v)) v = 1;
        state.rule.definition.multipivot2[field] = v;
      }
      break;
    case 'pricedate':
      {
        let v = new Date(value);
        if (Number.isNaN(v)) v = new Date();
        state.rule.definition.multipivot2[field] = v;
      }
      break;
    case 'attrs':
      state.rule.definition.multipivot2[field][subfield] = value;
      break;
    case 'levels':
      {
        let v = Number(value);
        if (Number.isNaN(v)) v = 1;
        state.rule.definition.multipivot2[field][subfield] = Number(v);
      }
      break;
    case 'powercurve':
      state.rule.definition.multipivot2.powercurve.family[subfield] = value;
      break;
    default:
  }
  // eslint-disable-next-line no-use-before-define
  recomputeMultipivot2Parameters(state, settings);

  recomputeall(state);
};

function numbercompare(parta, partb, attr, rule, locale, settings, t) {
  const va = getPartValue(parta, attr.name, getAttrSet(parta, attr.name, rule.tag, rule.definition.common.pmpattr, settings), t);
  const vb = getPartValue(partb, attr.name, getAttrSet(partb, attr.name, rule.tag, rule.definition.common.pmpattr, settings), t);
  if (va === undefined) return 1;
  if (vb === undefined) return -1;
  return (va < vb) - (vb < va);
}
const comparers = {
  string: (parta, partb, attr) => {
    const a = parta[attr.name];
    const b = partb[attr.name];
    return a.v.localeCompare(b.v);
  },
  collection: (parta, partb, attr) => {
    const a = parta[attr.name];
    const b = partb[attr.name];
    return a.v.localeCompare(b.v);
  },
  localizedstring: (parta, partb, attr, rule, locale) => {
    const a = parta[attr.name];
    const b = partb[attr.name];
    const al = localization.methods.localized(a.v, locale);
    const bl = localization.methods.localized(b.v, locale);
    return al.localeCompare(bl);
  },
  number: numbercompare,
  amount: numbercompare,
  pricing: numbercompare,
  boolean: (parta, partb, attr, rule, locale, settings, t) => {
    const a = getPartValue(parta, attr.name, getAttrSet(parta, attr.name, rule.tag, rule.definition.common.pmpattr, settings), t);
    const b = getPartValue(partb, attr.name, getAttrSet(parta, attr.name, rule.tag, rule.definition.common.pmpattr, settings), t);
    if (a && !b) return 1;
    if (b) return -1;
    return 0;
  },
  date: (parta, partb, attr) => {
    const a = parta[attr.name];
    const b = partb[attr.name];
    const d1 = new Date(a);
    const d2 = new Date(b);
    return (d1 < d2) - (d2 < d1);
  },
  interval: (parta, partb, attr) => {
    const a = parta[attr.name];
    const b = partb[attr.name];
    if (!(a && a.v && a.v.from && !Number.isNaN(a.v.from))) return -1;
    if (!(b && b.v && b.v.from && !Number.isNaN(b.v.from))) return 1;
    const d1 = a.v.from;
    const d2 = b.v.from;
    return (d1 < d2) - (d2 < d1);
  },
};
const directions = {
  asc(x) { return x; },
  desc(x) { return -x; },
};

function metacompare(type, name, a, b, attr, rule, locale, settings, t) {
  if (a[name] === undefined && b[name] === undefined) return 0;
  if (a[name] === undefined) return 1;
  if (b[name] === undefined) return -1;
  return comparers[type](a, b, attr, rule, locale, settings, t);
}
export const sortParts = (state, data) => {
  const {
    attr, direction = 'asc', locale, settings,
  } = data;
  const { name, type } = attr;
  if (!state.single) {
    store.parts.sort((a, b) => directions[direction](metacompare(type, name, a, b, attr, state.rule, locale, settings, state.currenttime)));
  } else {
    store.parts = [store.parts[0], ...store.parts.slice(1).sort((a, b) => directions[direction](metacompare(type, name, a, b, attr, state.rule, locale, settings, state.currenttime)))];
  }
  const sidx = Symbol.for('partindex');
  store.parts.forEach((p, idx) => {
    p[sidx] = idx;
  });
};

export const saveRule = (state, data) => {
  // clearRuleStore(state);
  setRule(state, data);
};

export const updateRuleName = (state, label) => {
  state.rule.label = label;
};
export const updateRuleT = (state, t) => {
  state.rule.t = t;
};
export const initPriceSet = (state, priceset) => {
  state.priceset = priceset;
};

export const updatePriceSet = (state, { priceset, locale, settings }) => {
  state.priceset = priceset;

  const rule = findCurrentRule(state.rulestore, priceset, state.currenttime)
    || mkEmptyRule(state.emptyrule, state.priceset, state.family);

  // load it
  setRule2(state, { rule, locale, settings });
  rule.rules.forEach((r) => {
    state.computed[r.tag] = true;
  });
};

export const setRuleCurrency = (state, { currency, locale, settings }) => {
  Vue.set(state.rule, 'currency', currency);
  if (state.rule.definition.pivot) {
    const pivot = state.rule.definition.pivot.find((a) => (a.name === 'price'));
    pivot.v.unit = currency;
  }
  // we need to recompute everything
  resetSets(state);
  const sidx = Symbol.for('partindex');
  store.parts.forEach((part) => {
    state.rule.func(part, true, false);
    const idx = state.partsPage.findIndex((p) => p.nridx === part[sidx]); // FIXME there is a better way
    if (idx > -1) state.partsPage[idx] = refactorPart(state, { part, locale, settings });
  });
};

export const updateRuleActivate = (state, value) => {
  Vue.set(state.rule, 'active', value);
};

export const setCurrentTime = (state, t) => {
  state.currenttime = t;
  // we need to change for all parts
  const s = Symbol.for('actualdateindex');
  const actualdate = state.currenttime;
  const nonpriced = [];
  store.parts.forEach((part) => {
    if (part.price || part[state.rule.definition.common.pmpattr] || part.pricingmethod || part.fullcostingratio) {
      if (part.fullcostingratio) {
        Vue.set(part.fullcostingratio.DEFAULT, s, findactualdate(part.fullcostingratio.DEFAULT, actualdate));
      }
      if (part.pricingmethod) {
        Vue.set(part.pricingmethod.DEFAULT, s, findactualdate(part.pricingmethod.DEFAULT, actualdate));
      }
      if (part.price) {
        const idx = findactualdate(part.price[state.rule.tag], actualdate);
        Vue.set(part.price[state.rule.tag], s, idx);
        setOneTimeAndPriceSet(part, state.rule.tag); // we should vue.set ?
      }
      if (part[state.rule.definition.common.pmpattr]) {
        const { set, idx } = state.lib.pmpset(part, state.rule.definition.common.pmpattr, state.rule.definition, actualdate, findactualdate);
        part[state.rule.definition.common.pmpattr][Symbol.for('actualset')] = set;
        Vue.set(part[state.rule.definition.common.pmpattr][set], s, idx);
      }
    }
    if (state.single && state.rule.tag && methods[state.rule.method].kit && (part.id.v !== state.singlePart.id.v)) {
      if (part.price === undefined || part.price[state.rule.tag] === undefined) {
        nonpriced.push(part.name.v);
      } else {
        const price = getPartValue(part, 'price', state.rule.tag, state.currenttime);
        // const idx = findactualdate(part.price[state.rule.tag], findpricedate(state.rule.t, state.rule.t2));
        if (!price || isZero(price)) {
          nonpriced.push(part.name.v);
        }
      }
    }
  });
  state.nonpriced = Object.freeze(nonpriced);
  recomputeall(state);
};

export const graphmode = (state, value) => {
  state.graphmode = !!value;
};

export const setCurrencyTag = (state, value) => {
  state.rule.definition.currencytag = value;
  recomputeall(state);
};

export const setMarginBasis = (state, value) => {
  if (state.rule.definition.common.margins === undefined) Vue.set(state.rule.definition.common, 'margins', {});
  Vue.set(state.rule.definition.common.margins, 'basis', value);
  recomputeall(state);
};

export const setFullcostingThreshold = (state, value) => {
  if (state.rule.definition.common.fullcosting === undefined) Vue.set(state.rule.definition.common, 'fullcosting', {});
  Vue.set(state.rule.definition.common.fullcosting, 'mode', value ? 'full' : 'threshold');
  recomputeall(state);
};

export const setMissingMaterial = (state, { value, settings }) => {
  Vue.set(state.rule.definition.common, 'missingmaterial', value);
  // eslint-disable-next-line no-use-before-define
  recomputeMultipivot2Parameters(state, settings);
  recomputeall(state);
};

export const setMinimalpriceBasis = (state, value) => {
  if (state.rule.definition.common.minimalprice === undefined) Vue.set(state.rule.definition.common, 'minimalprice', {});
  Vue.set(state.rule.definition.common.minimalprice, 'basis', value);
  recomputeall(state);
};

export const setCompetitionLevel = (state, { value, settings }) => {
  Vue.set(state.rule.definition.common, 'competitionlevel', value);
  if (state.rule.definition.pivottype === 'multi2') {
    // eslint-disable-next-line no-use-before-define
    recomputeMultipivot2Parameters(state, settings);
    recomputeall(state);
  }
};

export const setFeatureImpact = (state, { value, settings }) => {
  Vue.set(state.rule.definition.common, 'featureimpact', value);
  // eslint-disable-next-line no-use-before-define
  recomputeMultipivot2Parameters(state, settings);
  recomputeall(state);
};

export const setGraphtype = (state, name) => {
  state.graphtype = name;
};

export const shiftFromBacklog = (state) => {
  state.backlog.shift();
};

export const setSingle = (state, single) => {
  state.single = single;
};
export const setSinglePart = (state, singlePart) => {
  state.singlePart = singlePart;
};

export const setSimilars = (state, similars) => {
  state.rule.similars = Object.assign(state.rule.similars, similars);
};

export const movePart = (state, id) => {
  const idx = store.parts.findIndex((part) => part.id.v === id);
  if (idx < 0) return;
  store.parts.splice(idx, 1);
  const references = state.rule.definition.pivot.map((a) => a.name);
  resetaverage(references, state.averagereferences);
  store.parts.forEach((p) => computeaverage(references, state.averagereferences, p));
  finishaverage(references, state.averagereferences);
};

export const setXaxis = (state, name) => {
  state.xaxis = name;
};

export const setGraphSort = (state, name) => {
  state.graphsort = name;
};

export const setGraphRules = (state, name) => {
  state.graphrules = name;
};

export const setProgress = (state, msg) => {
  state.progressmessage = msg;
};

function updateAttrsLayout(state, data, def) {
  data.attrs = [...new Set([...data.definition.show, ...(data.definition.attributes || []).map((a) => a.name)])];
  (data.definition.pivot || []).forEach((a) => {
    a.type = def[a.name].type;
  });

  data.attrslayout = data.attrs.reduce((_, name) => {
    const d = def[name];
    const rootset = state.sets.sets[d.root];
    const idx = rootset.fixed ? 0 : 1;
    let group = _[idx].find((g) => g.id === rootset.id);
    if (group === undefined) {
      group = {
        id: rootset.id,
        name: rootset.name,
        label: rootset.label,
        attrs: [],
        closed: false,
      };
      _[idx].push(group);
    }
    group.attrs.push(d);
    return _;
  }, [[], []]);

  // Add all dimentions
  if (state.alldimensions) {
    const allsets = Object.values(state.sets.sets);
    const did = (allsets.find((s) => (s.name === 'dimensions')) || {}).id;
    const rootset = (allsets.find((s) => (s.name === 'alldimensions')) || {});
    if (did && rootset.id) {
      const attrName = [...(state.defs.grouped[1].find((b) => (b.id === did)) || {}).attrs];
      data.attrslayout[1].push({
        id: rootset.id,
        name: rootset.name,
        label: rootset.label,
        attrs: attrName.map((name) => def[name]),
        closed: true,
      });
    }
  }

  // Order attrslayout
  data.attrslayout.map((bloc) => {
    bloc.map((group) => {
      group.attrs = group.attrs.sort((a1, a2) => state.defs.order.indexOf(a1.name) - state.defs.order.indexOf(a2.name));
      return group;
    });
    bloc = bloc.sort((g1, g2) => state.sets.order.indexOf(g1.name) - state.sets.order.indexOf(g2.name));
    return bloc;
  });
  return data;
}

export const addShow = (state, data) => {
  const { toAdd, locale, settings } = data;
  state.rule.definition.show.push(toAdd);

  state.rule = updateAttrsLayout(state, state.rule, state.defs.defs2);
  state.partsPage = state.partsPage.map((p) => refactorPart(state, { part: store.parts[p.nridx], locale, settings }));
};

export const removeShow = (state, data) => {
  const { toRemove, locale, settings } = data;
  state.rule.definition.show = state.rule.definition.show.filter((a) => a !== toRemove);

  state.rule = updateAttrsLayout(state, state.rule, state.defs.defs2);
  state.partsPage = state.partsPage.map((p) => refactorPart(state, { part: store.parts[p.nridx], locale, settings }));
};

export const addRuleAttr = (state, { name, weighttype, pivot, currency, locale, settings }) => { // eslint-disable-line
  const def = state.defs.defs2[name];
  const set = state.sets.sets[def.root].name;
  const defaultunit = (def.settings && def.settings.unit ? def.settings.unit : undefined)
    || (['amount', 'pricing'].includes(def.type) && (state.rule.currency || currency)) || '';

  if (pivot) {
    state.rule.definition.pivot.push({
      name,
      type: def.type,
      set,
      v: mkemptyvalue(def.type, defaultunit),
    });
  }
  state.rule.definition.attributes.push({
    name,
    type: weighttype,
    set,
    v: mkemptyvalue(weighttype, weighttype === 'amount' ? (state.rule.currency || currency) : ''),
  });
  if (!pivot) {
    store.parts.forEach((p) => {
      if (p[name] === undefined) {
        p[name] = {
          name,
          type: def.type,
          set,
          v: mkemptyvalue(def.type, defaultunit),
        };
      }
    });
  }

  state.rule = updateAttrsLayout(state, state.rule, state.defs.defs2);
  if (state.rule.clearcache) {
    state.rule.clearcache();
  }
  state.partsPage = state.partsPage.map((p) => refactorPart(state, { part: store.parts[p.nridx], locale, settings }));
  recomputeall(state);
};
export const removeAttribute = (state, { name, locale, settings }) => {
  const aidx = state.rule.definition.attributes.findIndex((e) => (e.name === name));
  const pidx = state.rule.definition.pivot.findIndex((e) => (e.name === name));
  state.rule.definition.attributes.splice(aidx, 1);
  if (pidx >= 0) state.rule.definition.pivot.splice(pidx, 1);
  state.rule = updateAttrsLayout(state, state.rule, state.defs.defs2);
  state.partsPage = state.partsPage.map((p) => refactorPart(state, { part: store.parts[p.nridx], locale, settings }));
};

export const setCostPlus = (state, costPlus) => {
  if (!state.rule.definition.costplus) state.rule.definition.costplus = [];
  state.rule.definition.costplus = costPlus;
  store.parts.forEach((p) => { p[Symbol.for('Cost+rate')] = undefined; });
  state.rule.definition.costPlusFunc = state.rule.costPlusFactory(costPlus, state.defs.defs2);
  recomputeall(state);
};

export const updateParentPriceSet = (state, parent) => {
  Vue.set(state.rule, 'parent', parent);
  state.rule.rules = findRulePath(state, state.rule).reverse();
  recomputeall(state);
};

export const updatePricingMethod = (state, { method, settings }) => {
  state.rule.method = method;
  const { lib } = state;
  state.rule.rawfunc = methods[method].ctr(state.rule, state.defs.defs2, lib, settings).price;
  recomputeall(state);
};

export const updateAutoCRC = (state, data) => {
  const {
    value,
    kind,
    idx1,
    idx2,
  } = data;
  if (kind === 'enabled') {
    state.rule.definition.autocrc[kind] = value;
  } else if (['maxvalue', 'defaultvalue', 'onlyto', 'stopvalue'].includes(kind)) {
    state.rule.definition.autocrc[kind] = value;
  } else {
    const item = state.rule.definition.autocrc[kind][idx1];
    if (idx2 === undefined) {
      state.rule.definition.autocrc[kind][idx1] = value;
    } else {
      state.rule.definition.autocrc[kind][idx1][idx2] = value;
    }
  }
  // we need to recompute everything
  recomputeall(state);
};

export const adjustRulePivot = (state) => {
  state.rule.definition.pivot.forEach((ref) => {
    const val = state.averagereferences[ref.name];
    if (val !== undefined) {
      const { value } = val;
      ref.v.fixed = value.toFixed(2);
      ref.v.number = value;
    }
  });
  recomputeall(state);
};
// this is just a dichotomy search
function autoPivotGeneric(payload, context, setter, compute, computeprices) {
  const { settings, target, threshold } = payload;
  const ctx = typeof context === 'function' ? context() : undefined;
  let max = setter(ctx);
  let oldmax = max;
  let min = 0;
  do {
    computeprices();
    const currentvalue = compute(ctx);
    if (Math.abs(currentvalue - target) < (threshold || 0.5)) break;
    if (currentvalue > target) {
      oldmax = max;
      max = (max + min) / 2;
    } else {
      min = max;
      max += (oldmax - max) / 2;
    }
    setter(ctx, max);
  } while (true); // eslint-disable-line
}

// left for reference, but not used anymore
export const autoPivotMargin = (state, { settings, targetmargin }) => {
  const margintype = ((settings.margins || {}).methods || [])[0] || 'arithmetic';
  // find the max pivot
  const rulecurrency = state.rule.currency || settings.currency; // FIXME : we should find parent rule currency
  const targetcurrency = rulecurrency;
  // set boundaries
  let max = (1 + Number(targetmargin) / 100) * store.parts.reduce((_, part) => {
    let activeprice = getPartValue(part, state.rule.definition.common.priceattr, state.priceset, state.currenttime);
    if (activeprice === undefined) return _;
    const activepricecurrency = getPartCurrency(part, state.rule.definition.common.priceattr, state.priceset)
      || getPartAltCurrency(part, state.rule.definition.common.priceattr, state.priceset) || state.rule.currency;
    if (activepricecurrency !== targetcurrency) {
      activeprice *= lookupRate(state, activepricecurrency, targetcurrency, settings.ratetag.price, settings);
    }
    if (activeprice < _) return _;
    return activeprice;
  }, 0);
  let oldmax = max;
  let min = 0;
  // set pivot to max
  const pivotIndex = state.rule.definition.pivot.findIndex((ref) => (ref.name === state.rule.definition.common.priceattr));
  if (pivotIndex < 0) return;
  state.rule.definition.pivot[pivotIndex].v.fixed = max.toFixed(2);
  state.rule.definition.pivot[pivotIndex].v.number = max;
  do {
    recomputeall(state);
    // let's find the margins

    const currentmargin = margintype === 'arithmetic'
      ? computestdmargin(false, state.rule.definition.common.averagediscount, state.stats.margins)
      : computeweightedmargin(state.stats.turnover, state.stats.purchase, state.rule.definition.common.averagediscount, settings.marquemode); // (sum / count) * 100;
    if (Math.abs(currentmargin - targetmargin) < 0.5) break; // we are done, let's get out of here
    // if not, we need another round
    if (currentmargin > targetmargin) {
      oldmax = max;
      max = (max + min) / 2;
    } else {
      min = max;
      max += (oldmax - max) / 2;
    }
    state.rule.definition.pivot[pivotIndex].v.fixed = max.toFixed(2);
    state.rule.definition.pivot[pivotIndex].v.number = max;
  } while (true); // eslint-disable-line
};

function computeincrease(stats = {}) {
  const { variations } = stats;
  return (1 - (variations[0] / (variations[1] + variations[0] + variations[2]))) * 100;
}

// left for reference, but not used anymore
export const autoPivotIncrease = (state, { settings, targetincrease }) => {
  let max = 10;
  let oldmax = max;
  let min = 0;
  state.rule.definition.multipivot.level = max;
  do {
    recomputeall(state);
    // were are we, let's get our increase
    const currentincrease = computeincrease(state.stats);
    if (Math.abs(currentincrease - targetincrease) < 0.5) break; // we are done, let's get out of here
    if (currentincrease > targetincrease) {
      oldmax = max;
      max = (max + min) / 2;
    } else {
      min = max;
      max += (oldmax - max) / 2;
    }
    state.rule.definition.multipivot.level = max;
  } while (true); // eslint-disable-line
};

export const autoPivot = (state, {
  settings, target, autopivotmode,
}) => {
  if (autopivotmode === 'margin') {
    return autoPivotGeneric(
      { settings, target },
      () => {
        const margintype = ((settings.margins || {}).methods || [])[0] || 'arithmetic';
        // find the max pivot
        const rulecurrency = state.rule.currency || settings.currency; // FIXME : we should find parent rule currency
        const targetcurrency = rulecurrency;
        const pivotIndex = state.rule.definition.pivot.findIndex((ref) => (ref.name === state.rule.definition.common.priceattr));
        return {
          margintype,
          targetcurrency,
          pivotIndex,
          targetmargin: target,
        };
      },
      (ctx, value) => {
        let max;
        if (value === undefined) {
          max = (1 + Number(ctx.targetmargin) / 100) * store.parts.reduce((_, part) => {
            let activeprice = getPartValue(part, state.rule.definition.common.priceattr, state.priceset, state.currenttime);
            if (activeprice === undefined) return _;
            const activepricecurrency = getPartCurrency(part, state.rule.definition.common.priceattr, state.priceset)
              || getPartAltCurrency(part, state.rule.definition.common.priceattr, state.priceset) || state.rule.currency;
            if (activepricecurrency !== ctx.targetcurrency) {
              activeprice *= lookupRate(state, activepricecurrency, ctx.targetcurrency, settings.ratetag.price, settings);
            }
            if (activeprice < _) return _;
            return activeprice;
          }, 0);
        } else {
          max = value;
        }
        state.rule.definition.pivot[ctx.pivotIndex].v.fixed = max.toFixed(2);
        state.rule.definition.pivot[ctx.pivotIndex].v.number = max;
        return max;
      },
      (ctx) => {
        const currentmargin = ctx.margintype === 'arithmetic'
          ? computestdmargin(false, state.rule.definition.common.averagediscount, state.stats.margins)
          : computeweightedmargin(state.stats.turnover, state.stats.purchase, state.rule.definition.common.averagediscount, settings.marquemode); // (sum / count) * 100;
        return currentmargin;
      },
      () => recomputeall(state),
    );
  }
  return autoPivotGeneric(
    { settings, target, threshold: (1 / state.partsLength) * 100 },
    undefined,
    (ctx, value) => {
      state.rule.definition.multipivot.level = value === undefined ? 10 : value;
      return state.rule.definition.multipivot.level;
    },
    () => computeincrease(state.stats),
    () => recomputeall(state),
  );
};

export const setHighlightedParts = (state, { highlightedParts, status }) => {
  state.rule.definition.highlightedParts = highlightedParts;
  state.rule.review = status;
};

function centileFromSorted(a, p, attr) {
  const q = a.sort((x, y) => (x[attr] - y[attr]));
  const r = (q.length - 1) * p;
  const b = Math.floor(r);
  return (q[b + 1] === undefined)
    ? q[b][attr]
    : (q[b][attr] + (r - b) * (q[b + 1][attr] - q[b][attr]));
}

function computepivotparams(origparts, rule, accessor, featurevaluefoo, settings, crcfoo) {
  const parts = origparts.map((part, i) => ({
    index: i,
    name: part.name.v,
    familyprice: accessor(part, rule.multipivot2.attrs.price, rule.multipivot2.pricedate) || 0,
    dimension: accessor(part, rule.multipivot2.attrs.dimension) || 0,
    sales: accessor(part, rule.multipivot2.attrs.sales) || 0,
    computed: featurevaluefoo(part),
    crc: accessor(part, rule.multipivot2.attrs.crc) || 0,
  }));
  parts.sort((a, b) => (a.dimension - b.dimension));

  // weighted average price
  const wavg = {
    family: { weight: 0, sum: 0 },
    all: { weight: 0, sum: 0 },
    turnover: { lf: 0, sf: 0 },
    qties: { weight: 0, sum: 0 },
  };
  const blocksize = Math.floor(parts.length / 2 /* ** rule.multipivot2.blocksize */);
  const count = { lf: 0, sf: 0 };
  const blocks = {
    lower: parts.slice(0, blocksize),
    upper: parts.slice(-blocksize),
  };
  const { competitionlevel } = rule.common;
  const { min: minincrease, inc: targetincrease } = settings.competition.levels.find((l) => (l.name === competitionlevel));
  for(let i = 0; i < parts.length; i++) { // eslint-disable-line
    const part = parts[i];
    const { familyprice, dimension, sales } = part;
    part.generalprice = rule.multipivot2.powercurve.general.a * (dimension ** rule.multipivot2.powercurve.general.b);
    if (familyprice) {
      wavg.family.weight += sales;
      wavg.family.sum += familyprice * sales;
    }
    if (part.dimension) {
      wavg.all.weight += sales;
      wavg.all.sum += part.generalprice * sales;
    }
    const type = (sales > rule.multipivot2.lfthreshold) ? 'lf' : 'sf';
    count[type]++; // eslint-disable-line
    if (familyprice) {
      wavg.turnover[type] += familyprice * sales;
    }
    if (type === 'sf') {
      wavg.qties.weight += 1;
      wavg.qties.sum += sales;
    }
  }
  Object.entries(wavg).forEach(([key, value]) => {
    if (key === 'turnover') return;
    wavg[key] = value.sum / value.weight;
  });
  const avg = {
    upper: { familyprice: 0, generalprice: 0, dimension: 0 },
    lower: { familyprice: 0, generalprice: 0, dimension: 0 },
  };
  Object.entries(blocks).forEach(([key, block]) => {
    block.forEach((part) => {
      avg[key].familyprice += part.familyprice;
      avg[key].dimension += part.dimension;
      avg[key].generalprice += part.generalprice;
    });
    avg[key].familyprice /= block.length;
    avg[key].dimension /= block.length;
    avg[key].generalprice /= block.length;
  });
  const ratios = {
    wratio: wavg.family / wavg.all,
    fratio: avg.upper.familyprice / avg.lower.familyprice,
    gratio: avg.upper.generalprice / avg.lower.generalprice,
  };
  ratios.computedexpected = Math.max(
    Math.min(
      1 + (ratios.gratio - 1) / ratios.wratio,
      ratios.gratio * 2,
    ),
    ratios.gratio / 2,
  );
  if (rule.multipivot2.levels.manualexpected) {
    ratios.expected = rule.multipivot2.levels.manualexpected;
  } else {
    ratios.expected = ratios.computedexpected;
  }
  const minmax = {
    min: { value: 1, price: 0 },
    max: {
      value: Math.max(1, ratios.fratio) * rule.multipivot2.dimension + ratios.expected * (1 - rule.multipivot2.dimension),
    },
  };
  minmax.max.price = wavg.family * Math.sqrt(minmax.max.value);
  minmax.min.price = wavg.family / Math.sqrt(minmax.max.value);
  const powercurve = {
    b: Math.log(minmax.max.price / minmax.min.price) / Math.log(avg.upper.dimension / avg.lower.dimension),
  };
  powercurve.a = minmax.min.price / avg.lower.dimension ** powercurve.b;
  const turnover = {
    lf: 0,
    sf: 0,
  };
  let avgcrc = {
    sum: 0,
    base: 0,
  };
  for(let i = 0; i < parts.length; i++) { // eslint-disable-line
    const part = parts[i];
    part.referenceprice = powercurve.a * (part.dimension ** powercurve.b);
    part.referencepricewithcoef = part.referenceprice * part.computed.coef + part.computed.addon;
    part.levelwithcoef = part.familyprice / (part.referencepricewithcoef || 1);
    part.levelwithoutcoef = part.levelwithcoef / (1 + (part.crc || 0));
    const type = (part.sales > rule.multipivot2.lfthreshold) ? 'lf' : 'sf';
    turnover[type] += part.referencepricewithcoef * part.sales;
    const base = part.familyprice * part.sales;
    if (type === 'sf') {
      const crc = crcfoo(origparts[part.index], { pmp: part.referenceprice / 4.5, sales: wavg.qties, stop: wavg.qties });
      avgcrc.sum += (crc || 0) * base;
      avgcrc.base += base;
    }
  }
  turnover.all = turnover.lf + turnover.sf;
  turnover.lfr = turnover.lf / turnover.all;
  turnover.sfr = turnover.sf / turnover.all;
  avgcrc = avgcrc.base === 0 ? 0 : avgcrc.sum / avgcrc.base;
  if (avgcrc <= 0 && count.lf > 0) avgcrc = rule.autocrc.defaultvalue / 100;

  const partsv = [...parts].sort((a, b) => (a.sales - b.sales));
  const bcount = {
    lf: Math.max(count.lf, parts.length * (rule.multipivot2.minparts || 0.2)),
    sf: Math.max(count.sf, parts.length * (rule.multipivot2.minparts || 0.2)),
  };
  const blocksv = {
    lower: partsv.slice(0, bcount.sf).filter((p) => (p.sales > rule.multipivot2.minqty)),
    upper: partsv.slice(-bcount.lf),
  };
  const targetincreaseRecomputed = Math.max(wavg.family * -0.0001 + targetincrease, rule.multipivot2.minincrease || 0.6);

  const levels = {
    lf: {
      targetgain: blocksv.upper.length === 0 ? 0 : centileFromSorted(blocksv.upper, targetincreaseRecomputed, 'levelwithoutcoef'),
      zerogain: { sum: 0, base: 0 },
    },
    sf: {
      targetgain: blocksv.lower.length === 0 ? 0 : centileFromSorted(blocksv.lower, targetincreaseRecomputed, 'levelwithcoef'),
      zerogain: { sum: 0, base: 0 },
    },
  };
  blocksv.upper.forEach((part) => {
    levels.lf.zerogain.sum += (part.familyprice * part.sales) / (1 + (part.crc || 0));
    levels.lf.zerogain.base += part.referenceprice * part.sales;
  });
  blocksv.lower.forEach((part) => {
    levels.sf.zerogain.sum += part.familyprice * part.sales;
    levels.sf.zerogain.base += part.referencepricewithcoef * part.sales;
  });
  levels.lf.zerogain = levels.lf.zerogain.sum / levels.lf.zerogain.base;
  levels.sf.zerogain = levels.sf.zerogain.sum / levels.sf.zerogain.base;
  levels.lf.mingain = levels.lf.zerogain * (1 + minincrease);
  levels.sf.mingain = levels.sf.zerogain * (1 + minincrease);

  ['lf', 'sf'].forEach((type) => {
    levels[type].first = Math.max(levels[type].mingain, levels[type].targetgain);
  });
  if (count.sf <= 0) levels.sf.first = levels.lf.first * (1 + avgcrc);
  levels.avg = levels.sf.first * turnover.sfr + levels.lf.first * turnover.lfr;

  levels.computedlf = levels.avg / ((1 + avgcrc) * turnover.sfr + turnover.lfr);
  if (rule.multipivot2.levels.manuallf) {
    levels.lf.final = rule.multipivot2.levels.manuallf;
  } else {
    levels.lf.final = levels.computedlf;
  }
  levels.sf.final = levels.lf.final * (1 + avgcrc);

  const cutop = {
    upperbound: {
      upper: (c) => Math.max(c, levels.sf.final * 1.2),
      lower: (c) => Math.max(c, levels.sf.final * 1.15),
    },
    lowerbound: {
      upper: (c) => Math.min(c, levels.lf.final * 0.9),
      lower: (c) => Math.min(c, levels.lf.final * 0.8),
    },
  };
  ['upperbound', 'lowerbound'].forEach((range) => {
    levels[range] = {};
    ['upper', 'lower'].forEach((bound) => {
      levels[range][bound] = cutop[range][bound](centileFromSorted(parts.filter((p) => (p.sales > rule.multipivot2.minqty)), rule.multipivot2.limits[range][bound], 'levelwithcoef'));
    });
  });

  const result = {
    powercurve: {
      general: JSON.parse(JSON.stringify(rule.multipivot2.powercurve.general)),
      family: powercurve,
    },
    avg: {
      crc: avgcrc,
    },
    levels: {
      manuallf: rule.multipivot2.levels.manuallf,
      manualexpected: rule.multipivot2.levels.manualexpected,
      expected: ratios.expected,
      computedexpected: ratios.computedexpected,
      computedlf: levels.computedlf,
      lf: levels.lf.final,
      sf: levels.sf.final,
      upperbound: levels.upperbound,
      lowerbound: levels.lowerbound,
    },
  };
  return result;
}
export const recomputeMultipivot2Parameters = (state, settings) => {
  const defs = state.defs.defs2;
  const { multipivot2: pivotsettings } = state.rule.definition;
  const parent = state.rule.parent ? state.lib.getRuleFromRegistry(state.rule.rules[state.rule.rules.length - 2].id) : {};
  const rulecurrency = state.rule.currency || parent.currency || settings.currency;
  // compute the averages.
  const accessor = (part, attr, when = state.currenttime) => {
    const set = getAttrSet(part, attr, state.priceset, state.rule.definition.common.pmpattr, settings);
    return getPartValue(part, attr, set, when);
  };
  const cache = computearrayaverage2(store.parts, pivotsettings, accessor, state.rule.definition, state.lib, defs, pivotsettings.attrs.price);
  // compute the feature impact
  const featurevaluefoo = (part, referenceprice) => {
    const targetcurency = state.lib.getPartCurrency(part, pivotsettings.attrs.price, state.priceset)
      || getPartAltCurrency(part, pivotsettings.attrs.price, state.priceset) || rulecurrency;
    const rulerate = lookupRate(state, targetcurency, rulecurrency, settings.ratetag.price, settings);
    return featurevalue(
      part,
      state.rule.definition,
      cache,
      state.lib,
      defs,
      settings,
      rulerate,
      referenceprice,
      state.currenttime,
    );
  };
  function crcfoo(part, data) {
    return state.lib.crc(part, state.lib, state.priceset, state.currenttime, data, state.lib.rule2CRCParams(state.rule.definition.autocrc));
  }
  const params = computepivotparams(store.parts, state.rule.definition, accessor, featurevaluefoo, settings, crcfoo);
  state.rule.definition.multipivot2 = {
    ...state.rule.definition.multipivot2,
    ...params,
  };
  recomputeall(state);
};

export const setPivotType = (state, { pivottype, settings }) => {
  state.rule.definition.pivottype = pivottype;
  // update everything
  if (pivottype === 'multi2'
  && !(state.rule.definition.multipivot2 && state.rule.definition.multipivot2.powercurve
    && state.rule.definition.multipivot2.powercurve.family
    && state.rule.definition.multipivot2.powercurve.family.a)) {
    recomputeMultipivot2Parameters(state, settings);
  } else {
    recomputeall(state);
  }
};

export const setuplib = (state, settings) => {
  state.lib = mklib(state, settings, priorityfetch, crcdefs);
};

export const setImplementationRate = (state, { index, def }) => {
  if (state.rule.definition.implstrategy[index].type === 'progressive') {
    state.rule.definition.implstrategy[index].thresholdtree = def;
    state.rule.strategyrate[index] = state.lib.costPlusFactory(def, state.defs.defs2);
  }
  recomputeall(state);
};

export const setImplementationStrategy = (state, { index, key, value }) => {
  const [field, subfield] = key.split('.');
  const s = state.rule.definition.implstrategy;
  if (field === 'type') {
    s[index].type = value;
    if (s[index].type === 'progressive') {
      const defaults = {
        target: 'rawproposedprice',
        markup: 1.0,
        threshold: 0.2,
        thresholdtree: [{ decision: 0.2 }],
        period: 12,
        reference: { attr: 'price', date: (new Date()).toISOString().split('T')[0] },
      };
      Object.entries(defaults).forEach(([k, v]) => {
        if (s[index][k] === undefined) s[index][k] = v;
      });
    } else if (s[index].type === 'none') {
      const defaults = {
        reference: { attr: 'price', date: (new Date()).toISOString().split('T')[0] },
      };
      Object.entries(defaults).forEach(([k, v]) => {
        if (s[index][k] === undefined) s[index][k] = v;
      });
    }
  } else if (subfield) {
    if (s[index][field] === undefined) Vue.set(s[index], field, {});
    s[index][field][subfield] = value;
  } else {
    s[index][field] = value;
  }
  recomputeall(state);
};
